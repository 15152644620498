html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
}

h2 {
  letter-spacing: -1px;
}
