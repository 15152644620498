.mailtoui-modal {
  background-color: rgba(250,250,250,0.5);
  color: #fff;
}

.mailtoui-modal-head {
  background-color: #36c5f0;
}

.mailtoui-modal-title {
  color: #fff;
}

.mailtoui-modal-close:focus,
.mailtoui-modal-close:hover {
  color: white;
}

.mailtoui-modal-body {
  background-color: #efefef;;
}

.mailtoui-button:focus .mailtoui-button-content {
  background-color: #2eb67d;
  color: #ffffff;
}

.mailtoui-button-content,
.mailtoui-button-copy {
  background-color: #D8DCDF;
  color: #333;
}

.mailtoui-button-content:hover,
.mailtoui-button-content:focus,
.mailtoui-button-copy:hover,
.mailtoui-button-copy:focus {
  background-color: rgb(142, 143, 143);
  color: #ffffff;
}

.mailtoui-button-copy-clicked,
.mailtoui-button-copy-clicked:hover,
.mailtoui-button-copy-clicked:focus {
  background-color: #2eb67d;
  color: #fff;
}

.mailtoui-email-address {
  background-color: #909295;
  color: #ffffff;
}
